import React from 'react';
import { css } from '@emotion/react';

export default function PDF({ text, link }) {
  return (
    <div
      css={css`
        position: relative;
        display: inline-block;
        margin-right: var(--gutter);

        &:nth-of-type(6n) {
          margin-right: 0;
        }
      `}
    >
      <a
        css={css`
          display: inline-block;
          position: relative;
          width: calc(
            (((100vw - (var(--margin) * 2)) - (var(--gutter) * 11)) / 12)
            * 2 + (1 * var(--gutter))
          );
          transition: color 0.6s var(--curve);

          @media (max-width: 700px) {
            width: calc(
              (((100vw - (var(--margin) * 2)) - (var(--gutter) * 11)) / 12)
              * 3 + (2 * var(--gutter))
            );
          }

          &:hover {
            color: var(--primary);
            transition: color 0s;
          }
        `}
        target="_blank"
        rel="noreferrer"
        href={link}
      >
        <img
          css={css`
            width: calc(100% + 5.8rem);
            position: relative;
            left: -2.9rem;
          `}
          src="/images/paper.svg"
        />
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            bottom: 2rem;
            left: 0;
            right: 0;
          `}
        >
          <div
            className="type--60"
            css={css`
              //transform-origin: 50% 50%;
              transform: rotate(-55deg);
            `}
          >{text}</div>
        </div>
      </a>
    </div>
  )
}
